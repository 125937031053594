// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './pages/_index'

// createApp(App).use(router).mount('#app')
import { ComponentPublicInstance, createApp } from "vue";
import App from "./App.vue";
import router from "./pages/_index";

//Moved to Segment-based Deployment
//import LogRocket from 'logrocket';
//LogRocket.init('zbqbfd/prime-hire');


// import VueApexCharts from "vue3-apexcharts";


if (!localStorage.getItem("version")) {
  // remove old service worker
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
  // save only login
  const user = localStorage.getItem("login_user") || "";
  const pass = localStorage.getItem("login_pass") || "";
  localStorage.clear();
  localStorage.setItem("login_user", user);
  localStorage.setItem("login_pass", pass);
  localStorage.setItem("version", "2");
}

const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
  const error = err as {message: string, stack: string};
  window.analytics.track("Error: Vue", { error: error.message, stack: error.stack, info: info });
};

// app.config.warnHandler = (
//   msg: string,
//   instance: ComponentPublicInstance | null,
//   trace: string
// ) => {
//   //window.analytics.track('Warning', {msg, instance, trace});
// };

app.use(router);
// app.use(VueApexCharts);
// app.component("apexchart", VueApexCharts); // Registering it globally

app.mount("#app");
