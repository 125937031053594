<template>
  <div id="appRoot">
    <transition name="snackbar">
    <div v-if="topNotification!==''" class="snackbar">
        <div class="message">{{ topNotification }}</div>
        

        <CircleProgressbarVue style="margin-left: 5px;" :timer="6000" @click="topNotification=''">
          <button class="delete" aria-label="close"></button>
        </CircleProgressbarVue>
      </div>
    </transition>
    <Transition name="slidein-right" v-if="currentRouter.currentRoute.value.meta.nav != false">
      <NavBar v-if="isNavShowing"></NavBar>
    </Transition>
    <main @click.stop="userRequestNavOpen = false" :style="{ 'margin': isLoggedOut ? 0 : undefined }">
      <!-- <span style="position: absolute; top: 0; right: 0; margin: 2em;">
        <span class="icon">
          <i style="font-size: 1.2em;" class="fal fa-bell" />
        </span>
        <span style="font-size: 0.8em; position: absolute; top: -20px; right: -20px" class="tag is-rounded is-primary">{{
          notifications?.total() }}</span>
      </span> -->
      <span v-if="showNavBarButton" id="navToggleButton" class="icon"
        @click.stop="userRequestNavOpen = !userRequestNavOpen">
        <i class="fas fa-bars" />
      </span>
      <router-view @update:props="propsChanged" />
    </main>
  </div>
  <div class="modal" :class="{ 'is-active': modalInfo.active }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box" style="margin: 1em">
        <button class="modal-close is-large" aria-label="close" @click="modalInfo.active = false"></button>
        <hr v-if="modalInfo.color && modalInfo.color != 'primary'" class="bar"
          :style="{ 'border-color': modalInfo.color }" />
        <IconText icon="fas fa-exclamation-triangle" />
        <p style="margin: 1em;">
          {{ modalInfo.text }}
        </p>
        <ButtonIconText class="is-primary is-small is-fullwidth" text="Close" @clicked="modalInfo.active = false" />
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="modalInfo.active = false"></button>
  </div>
  <div class="modal" :class="{ 'is-active': modalInfoChooseOption.active }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box" style="margin: 1em">
        <button class="modal-close is-large" aria-label="close" @click="rejectmodalInfoChooseOption"></button>
        <hr v-if="modalInfo.color && modalInfo.color != 'primary'" class="bar"
          :style="{ 'border-color': modalInfo.color }" />
        <IconText icon="fas fa-exclamation-triangle" />
        <p style="margin: 1em;">
          {{ modalInfoChooseOption.text }}
        </p>
        <div v-if="modalInfoChooseOption.style === 'button'"
          class="buttons is-flex is-flex-direction-column is-align-items-start">
          <button style="width: 100%;" class="button" v-for="option in modalInfoChooseOption.values"
            @click="modalInfoChooseOption.accept(option.id)"> {{
      option.value }}</button>
        </div>
        <ButtonIconText class="is-primary is-small is-fullwidth" text="Close" @clicked="rejectmodalInfoChooseOption" />
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="rejectmodalInfoChooseOption"></button>
  </div>
  <div class="install-popup" v-if="showPopupInstall">
    <span class="close-icon" @click="showPopupInstall = false"><i class="fas fa-times"></i></span>
    <section v-if="currentOs === 'Android'">
      <h2>How to Install Prime Hire on Android</h2>
      <p>To install, follow these steps:</p>
      <ol>
        <li>Tap on the menu icon <i class="fa-bounce fa-light fas fa-ellipsis-vertical"></i>.</li>
        <li>Select "Add to Home screen".</li>
        <li>Follow the prompts to install.</li>
      </ol>
    </section>

    <!-- iOS Installation View -->
    <section v-else-if="currentOs === 'iOS'">
      <h2>How to Install Prime Hire on iOS</h2>
      <ol>
        <li>Tap on the share icon <i class="fa-sharp fa-bounce fa-light fa-arrow-up-from-square"></i> at the bottom of the screen.</li>
        <li>Choose "Add to Home Screen" or "Install App".</li>
        <li>Follow the prompts to install.</li>
      </ol>
    </section>

    <!-- Computer Installation View -->
    <!-- Dont need to show -->
    <section v-else-if="false">
      <h2>How to Install PWA on Computer</h2>
      <p>To install this PWA on your computer, follow these steps:</p>
      <ol>
        <li>Open Chrome browser.</li>
        <li>Go to the website.</li>
        <li>Click on the three dots icon (menu) at the top right corner.</li>
        <li>Select "Install Prime Hire".</li>
        <li>Follow the prompts to install.</li>
      </ol>
    </section>
  </div>
  <div class="install-popup" v-if="showPopupPush">
    <span class="close-icon" @click="showPopupPush = false"><i class="fas fa-times"></i></span>
    <section>
      <h2>Prime Hire requires push notifications permission</h2>
      <template v-if="currentPermission === 'default'">
        <p>To enable push notifications, click the button below:</p>
        <ButtonIconText class="is-primary is-fullwidth" :icon="'fa-solid fa-bell fa-fade fa-lg  '"
          text="Enable Push Notifications" @clicked="requestNotificationPermission" />
      </template>
      <template v-if="currentPermission === 'denied'">
        <p>To enable push notifications, check on your browser settings how to enable it or try re-installing the app
        </p>
      </template>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { isNavShowing, modalInfo, modalInfoChooseOption, userRequestNavOpen } from "./AppLayout";
import { ref, computed, onMounted } from 'vue'
import NavBar from "./components/NavBar.vue";
import router from "./pages/_index";
import routerAdm from "./pages/_indexAdmin";
import routerPublic from "./pages/_indexPublic";
import ButtonIconText from "./components/elements/ButtonIconText.vue";
import IconText from "./components/elements/IconText.vue";
import { requestNotificationPermission, showPopupInstall, showPopupPush, topNotification } from "./data/state";
import { detectOS } from "./generalFunctions";
import CircleProgressbarVue from "./components/elements/CircleProgressbar.vue";

const currentPermission = ref('granted');
if (!("Notification" in window)) {
  currentPermission.value = 'granted';
} else {
  currentPermission.value = Notification.permission;
}


currentPermission.value
const currentOs = detectOS();
const currentRouter = computed(() => {
  if (router.currentRoute.value.fullPath !== '/') {
    return router;
  }
  if (routerAdm.currentRoute.value.fullPath !== '/') {
    return routerAdm;
  }
  if (routerPublic.currentRoute.value.fullPath !== '/') {
    return routerPublic;
  }
  return router//default
})

const showNavBarButton = computed(() => {
  return currentRouter.value.currentRoute.value.name != 'login' && currentRouter.value.currentRoute.value.name != 'signout'
});

function rejectmodalInfoChooseOption() {
  modalInfoChooseOption.value.active = false;
  modalInfoChooseOption.value.reject()
}
function propsChanged(props: Record<string, any>, query: Record<string, any>) {
  // console.log("Log props changed", currentRouter.value.currentRoute.value.name);
  query = currentRouter.value.currentRoute.value.query;
  // console.log("To", currentRouter.value.resolve(
  //   {
  //     name: currentRouter.value.currentRoute.value.name!,
  //     params: props,
  //     query: query
  //   }
  // ).fullPath);
}

const isLoggedOut = computed(() => {
  return currentRouter.value.currentRoute.value.name == 'login';
});

</script>


<style lang="scss">
@import '../src/styles/theme.scss';

div#appRoot {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.modal-content {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

main {
  @include mobile {
    margin: 0;
    padding: 1em;
  }

  margin: 2em;
  margin-right: 0;
  margin-bottom: 0;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;


  #navToggleButton {
    display: none;

    @include mobile {
      display: inline-flex;
      align-self: start;
    }
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.install-popup {
  position: fixed;
  bottom: 20px;
  max-width: 600px;
  width: 90%;
  left: 48%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #333;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.snackbar {
  position: fixed;
  width: 80vw;
  max-width: 600px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 3px;
  border-radius: 5px;
  z-index: 99999;
  opacity: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;

.message {
  white-space: nowrap;
  height: 3em;
  flex-grow: 1; /* Occupy remaining space */
  text-align: center; /* Center the text horizontally */
  margin: auto 0;
  padding-top: 0.75em;
}

.close-btn {
  position: relative;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.close-btn:hover {
  color: #ccc;
}

.close-btn i {
  font-size: 14px;
}

.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
  border-radius: 50%;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform-origin: center;
  animation: progressAnimation 5s linear forwards;
}

@keyframes progressAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
}

</style>